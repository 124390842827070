//Color Tokens
$gray: #D3D1DC;
$gray100: #F5F4F8;
$gray300: #B0ADBA;
$blue: #0B3183;
$orange: #FF5823;
$red: #E10606;


// Font Family Tokens
// ------------------
$primary-font: "DM Sans", sans-serif;

// Font Weight Tokens

// Font Size Tokens

// Line Height Tokens

// Tranisiton Tokens
