@import 'Tokens';

// Smooth Scroll
html {
    //overflow-y: scroll;
    scroll-behavior: smooth;
  }

// Section Styles
.navbar {
    // Positioning
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Appearance
    background-color: #0B3183;

    // Size
    height: 80px;

    // Space
    padding: 8px 24px;

}

.hero {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Size
    height: 70vh;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.statement {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Size
    height: 400px;

    // Appearance
    background-color: $gray100;

    // Space

    // Border
    //border: 1px solid black;
}

.values {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    
    // Space
    padding-top: 96px;
    padding-bottom: 96px;

    // Border
    //border: 1px solid black;

}

.services {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    //height: 800px;

    // Space
    padding-top: 96px;
    padding-bottom: 96px;

    // Border
    //border: 1px solid black;

}

.projects {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    //height: 800px;

    // Space
    padding-top: 96px;
    padding-bottom: 96px;

    // Border
    //border: 1px solid black;

}

.about {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    //height: 800px;

    // Space
    padding-top: 96px;
    padding-bottom: 96px;

    // Appearance
    background-color: $gray100;

    // Border
    //border: 1px solid black;

}

.contact {
    // Positioning
    display: flex;
    justify-content: center;
    //align-items: center;

    // Size
    //height: 800px;

    // Space
    padding-top: 96px;
    padding-bottom: 96px;

    // Border
    //border: 1px solid black;

}

.footer {
    // Positioning
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Size
    height: 124px;

    // Space
    padding: 8px 64px;

    // Appearance
    background-color: $gray100;

    // Border
    //border: 1px solid black;

}





// Container Styles
//-----------------
.section-container {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;

    // Size
    width: 100%;
    max-width: 1440px;

    // Space
    gap: 56px;

    // Border
    //border: 1px solid black;

}

.statement-section-container {
    // Positioning
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Size
    //width: 100%;
    max-width: 1440px;
    height: 100%;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;

}

.hero-button-container {
    // Positioning
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.hero-content-container {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Size

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.value-card-container {
    // Positioning
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    // Size


    // Border
    //border: 1px solid black;
}

.footer-container {
    // Positioning
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Size
    width: 100%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;

}

.footer-action-container {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    // Size



    // Border
    //border: 1px solid black;
}

.logo-container {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    //border: 1px solid black;
    border-radius: 8px;
}

.service-card-content-container {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    // Size
    width: 30%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;

}

.service-card-text-container {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;

    // Size
    //width: 100%;
    //max-width: 1440px;

    // Space
    gap: 8px;

    // Border
    //border: 1px solid blue;
}

.service-card-illustration-container {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    width: 70%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid purple;

}

.projects-container {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    width: 100%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.project-card-text-container {
    // Positioning
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;

    // Size

    // Space
    gap: 8px;

    // Border
    //border: 1px solid black;
}

.project-card-icon-container {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    //width: 100%;
    height: 100%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.projects-header {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //align-items: center;

    // Size
    max-width: 664px;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.projects-button-container {
    // Positioning
    display: flex;
    justify-content: flex-end;
    align-items: center;

    // Size
    width: 100%;
    //max-width: 1440px;

    // Space
    //gap: 24px;

    // Border
    //border: 1px solid black;

}

.about-container {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Size
    width: 100%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.about-card-container {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Size
    width: 100%;
    //max-width: 1440px;

    // Space
    gap: 24px;

    // Border
    //border: 1px solid black;
}

.contact-container {
    // Positioning
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;

    // Size
    width: 100%;
    max-width: 800px;

    // Space
    padding: 40px 40px;
    gap: 48px;

    // Border
    border-radius: 16px;
    border: 1px solid $gray;

}





// Navigation Styles
//------------------
.nav-menu {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.nav-link {
    // Font
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 500;

    color: $gray;
    text-decoration: none;
}

.active-nav-item {
    color: white; /* Example color */
    font-weight: 700;
    /* Add other styling as needed */
  }

.navbar-logo {
    // Positioning
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
}



// Footer Styles
//--------------
.footer-logo {
    // Positioning
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
}





// Text Styles
//------------
.navbar-logo-text {
    // Font
    font-family: $primary-font;
    font-size: 28px;
    font-weight: 700;

    color: white;
    text-decoration: none;
    outline: none;
}
.footer-logo-text {
    // Font
    font-family: $primary-font;
    font-size: 36px;
    font-weight: 700;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.title {
    // Font
    font-family: $primary-font;
    font-size: 48px;
    font-weight: 700;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.subtitle {
    // Font
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 500;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.statement-title {
    // Font
    font-family: $primary-font;
    font-size: 48px;
    font-weight: 700;

    width: 50%;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.statement-subtitle {
    // Font
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 500;

    width: 50%;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.primary-button-text {

    color: white;

    // Font
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    text-decoration: none;
    outline: none;
}

.secondary-button-text {

    color: $blue;

    // Font
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    text-decoration: none;
    outline: none;
}

.card-title {
    // Font
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 700;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.card-subtitle {
    // Font
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 500;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.service-card-title {
    // Font
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 700;

    color: $blue;
    text-decoration: none;
    outline: none;
}

.service-card-subtitle {
    // Font
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 400;

    color: $blue;
    text-decoration: none;
    outline: none;
}




  // Button Styles
//----------------
.primary-button {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Appearance
    background-color: #FF6737;
    //color: white;
    border: none;
    border-radius: 12px;

    // Size
    //height: 48px;
    //width: 160px;

    // Space
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0;
    gap: 8px;

    outline: none;
    cursor: pointer;

    // Border
    //border: 1px solid #E74410;

    // Hover
    transition: background-color 0.3s;
}

/* Primary Button Hover and Active Styles */
.primary-button:hover {
    background-color: #E74410; /* Darker shade for hover state */
}

.primary-button:active {
    background-color: #CB3E0D; /* Even darker shade for active state */
    transform: scale(0.98); /* Slight scale down to simulate a press */
}

.wide-primary-button {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Appearance
    background-color: #FF6737;
    //color: white;
    border: none;
    border-radius: 12px;

    // Size
    //height: 48px;
    width: 100%;

    // Space
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0;

    outline: none;
    cursor: pointer;

    // Border
    //border: 1px solid #E74410;

    // Hover
    transition: background-color 0.3s;
}

/* Wide Primary Button Hover and Active Styles */
.wide-primary-button:hover {
    background-color: #E74410; /* Adjust the color to match your design */
}

.wide-primary-button:active {
    transform: scale(0.98); /* Example transformation */
}

.chevron-primary-button {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Appearance
    background-color: #FF6737;
    //color: white;
    border: none;
    border-radius: 12px;

    // Size
    //height: 48px;
    max-width: 256px;

    // Space
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0;
    gap: 8px;

    outline: none;
    cursor: pointer;

    // Border
    //border: 1px solid #E74410;

    // Hover
    transition: background-color 0.3s;
}

/* Chevron Primary Button Hover and Active Styles */
.chevron-primary-button:hover {
    background-color: #E74410; /* Adjust accordingly */
}

.chevron-primary-button:active {
    transform: scale(0.98);
}

.secondary-button {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Appearance
    background-color: $gray;
    //color: white;
    border: none;
    border-radius: 12px;

    // Size
    //height: 48px;
    //width: 160px;

    // Space
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0;

    outline: none;
    cursor: pointer;

    // Border
    //border: 1px solid #E74410;

    // Hover
    transition: background-color 0.3s;
}

/* Secondary Button Hover and Active Styles */
.secondary-button:hover {
    background-color: darken($gray, 10%); /* Use Sass function to darken */
}

.secondary-button:active {
    background-color: darken($gray, 20%); /* Darken on active/click */
    transform: scale(0.98);
}


// Card Styles
//-------------
.value-card {
    // Positioning
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;

    // Appearance
    background-color: $gray100;
    border-radius: 12px;

    // Size
    height: 200px;

    // Space
    padding: 32px;
    gap: 8px;
}

.service-card {
    // Positioning
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    //align-items: center;

    // Appearance
    //background-color: $gray100;
    border: 1px solid $gray;
    border-radius: 16px;

    // Size
    //width: 100%;
    height: 496px;

    // Space
    padding: 24px;
    gap: 24px;
}

.project-card {
    // Positioning
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    // Appearance
    background-color: white;
    border-radius: 12px;
    outline: none;
    cursor: pointer;

    // Size
    height: 400px;

    // Shadow
    box-shadow: 0px 4px 16px rgba(11, 49, 131, 0.24);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    //flex-shrink: 0;

    // Hover effect
  &:hover {
    transform: scale(1); // Slightly scale up
    //box-shadow: 0px 6px 20px rgba(11, 49, 131, 0.32); // Enhanced shadow
    box-shadow: inset 0px 0px 0px 4px $blue;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
  }

  // Active (Click) effect
  &:active {
    transform: scale(0.99); // Slightly scale down to simulate a press
  }
}

.project-card-inner-shadow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    //width: 100%;
    //height: 100%;

    // Space
    padding: 32px;
    gap: 8px;

    border-radius: 12px;
    
    box-shadow: 0px 4px 16px rgba(11, 49, 131, 0.24);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    // Hover effect
    &:hover {
        transform: scale(1); // Slightly scale up
        box-shadow: 0px 6px 20px rgba(11, 49, 131, 0.32); // Enhanced shadow
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        //box-shadow: 0px 4px 16px rgba(11, 49, 131, 0.24);
        //border: 4px solid $blue; // Add a border to highlight the card
    }

    // Active (Click) effect
    &:active {
        transform: scale(0.99); // Slightly scale down to simulate a press
    }

}

.about-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    gap: 12px;
    
}

.about-card-img-placeholder {
    // Positioning
    display: flex;
    justify-content: center;
    align-items: center;

    // Appearance
    background-color: $gray;
    border-radius: 16px;

    // Size
    height: 400px;
    width: 400px;

    // Space
    //gap: 8px;
}


// Form Styles
//-------------
.contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .field-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .message-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 256px;
        gap: 8px;
    }
  
    .form-group {
      display: flex;
      width: 100%;
      gap: 12px;
      //border: 1px solid $gray300;
    }

    input::placeholder, textarea::placeholder {
        font-family: $primary-font;
        font-size: 16px;
        font-weight: 500;
        color: $gray300;
      }
  
    input[type="text"],
    input[type="email"],
    textarea {
        flex: 1;
      background-color: $gray100;
      border: 1px solid $gray300;
      border-radius: 8px;
      padding: 8px;
    }
  
    .interest-area {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Creates 2 columns of equal width
        gap: 12px; // Adjusts the gap between grid items
        border: none;
  
      label {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: $primary-font;
        font-size: 16px;
        font-weight: 700;
        color: $gray300;
      }
    }
  }

  .field-title {
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 700;
    color: $blue;
  }

  .required {
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 700;
    color: $red;
  }

 

// Checkboxes
.checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.checkbox label {
  cursor: pointer;
  position: relative;
  padding-left: 35px; // Increased space for visual clarity
  color: $gray300;
  display: flex;
  align-items: center;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 24px; // Match the size of the checkbox representation
  height: 24px;
  margin-right: 10px;
}

.checkbox label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 2px solid $gray300;
  border-radius: 4px;
  background-color: transparent;
}

.checkbox input:checked + label::before {
  background-color: $blue;
  border-color: $blue;
}

.checkbox label::after {
  content: '';
  position: absolute;
  top: 9px;
  left: 11px; // Center horizontally based on the checkbox's dimensions
  width: 4px;
  height:8px;
  transform: translate(-50%, -50%) rotate(45deg);
  border: solid white;
  border-width: 0 3px 3px 0;
  opacity: 0;
}

.checkbox input:checked + label::after {
  opacity: 1;
}
